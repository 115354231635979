import {RecentClaim} from './../../../../models/recent-claim';
import {Component, OnInit, HostListener, Input} from '@angular/core';
import {Router} from '@angular/router';
import {CONTENT} from '../../../../content-management/content';
import * as _ from 'lodash';
import {ROUTINGCONSTANTS} from '../../../../shared/constants/routing-constants';
import {IMAGECONSTANTS} from '../../../../shared/constants/image-constants';
import {GtmEventsService} from '../../../../shared/services/gtm-events.service';
import {STATUSES} from '../../../../shared/constants/claims';
import {EncrDecrService} from '../../../../core/services/encr-decr.service';

@Component({
  selector: 'app-claims-history',
  templateUrl: './claims-history.component.html',
  styleUrls: ['./claims-history.component.scss']
})
export class ClaimsHistoryComponent implements OnInit {
  public content = CONTENT;
  public routingConstants = ROUTINGCONSTANTS;
  public imageConstants = IMAGECONSTANTS;
  public claimsHistory: RecentClaim[] = [];
  public noClaimHistory = false;
  public iconObj: any;
  public petSpeciesUrl: string;
  @Input() claims: RecentClaim[] = [];

  constructor(private router: Router,
              private encDcrService: EncrDecrService,
              private gtmService: GtmEventsService) {
  }

  ngOnInit() {
    this.getSpeciesIcons();
    if (window.innerWidth < 768) {
      this.claimsHistory = this.claims.slice(0, 3);
    } else {
      this.claimsHistory = this.claims;
    }
    this.checkForClaimsData();
  }

  navigateToClaimDetails(claimNumber, policyNumber, renewal) {
    this.router.navigate([this.routingConstants.claimDetails, claimNumber, this.encDcrService.set(policyNumber), renewal]);
  }

  checkForClaimsData() {
    if (this.claims.length === 0) {
      this.noClaimHistory = true;
    }
  }

  checkForPetType(policyNumber) 
  {
    let speciesFound = false;
    const policyInfoList = JSON.parse(sessionStorage.getItem("policyList"));
    const selectedPolicy = policyInfoList?.find(p=>p.policyNumber === policyNumber); 
    // const species = selectedPolicy.pet.species;
    // return species.toUpperCase() === "CANINE";
    switch(selectedPolicy?.pet?.species?.toUpperCase())
    {
      case "CANINE":
        this.petSpeciesUrl = this.iconObj?.canineIcon;
        speciesFound = true;
        break; 
      case "FELINE":
        this.petSpeciesUrl = this.iconObj?.felineIcon;
        speciesFound = true;
        break;
      case "REPTILE":
        this.petSpeciesUrl = this.iconObj?.reptileIcon;
        speciesFound = true;
        break;  
      case "AVIAN":
        this.petSpeciesUrl = this.iconObj?.avianIcon;
        speciesFound = true;
        break; 
      case "EXOTIC":
        this.petSpeciesUrl = this.iconObj?.exoticIcon;
        speciesFound = true;
        break; 
      default:
        this.petSpeciesUrl = this.iconObj?.canineIcon;
        speciesFound = true;
        break;
    }
    return speciesFound;
  }

  getSpeciesIcons() {
    const portalSpeciesIcons = JSON.parse(sessionStorage.getItem('portalSpeciesIcons'));
    if(portalSpeciesIcons) {
      this.filterIcons(portalSpeciesIcons);
    }
  }

  filterIcons(iconList) {
    if(iconList) {
      const canineIcon = iconList?.canineIcon?.fields?.file?.url;
        const felineIcon = iconList?.felineIcon?.fields?.file?.url;
        const exoticIcon = iconList?.exoticIcon?.fields?.file?.url;
        const reptileIcon = iconList?.reptileIcon?.fields?.file?.url;
        const avianIcon = iconList?.avianIcon?.fields?.file?.url;  
      
      this.iconObj = {
        canineIcon : canineIcon,
        felineIcon: felineIcon,
        reptileIcon: reptileIcon,
        avianIcon: avianIcon,
        exoticIcon: exoticIcon       
      }
    }
    
  }

  isInProcess(recentClaim: RecentClaim): boolean {
    return recentClaim && recentClaim.claimStatus.toLowerCase() === STATUSES.INPROCESS;
  }

  public getSubmittedAmount(recentClaim: RecentClaim) {
    let amount = 0;
    if (recentClaim) {
      amount = Number(recentClaim.totalClaimAmount);
    }
    return amount;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 768) {
      this.claimsHistory = this.claims.slice(0, 3);
    } else {
      this.claimsHistory = this.claims;
    }
  }

  viewAllClaims() {
    this.claimsHistory = this.claims;
  }
}
