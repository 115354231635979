import {Component, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import * as _ from 'lodash';

import {CONTENT} from '../../../content-management/content';
import {Claim} from '../../../models/claim';
import {ClaimDetail} from '../../../models/claim-details';
import {ClaimDetailsResponse} from '../../../models/response/claim-details-response';
import {ClaimSummaryResponse} from '../../../models/response/claim-summary-response';
import {ClaimsService} from '../../../modules/claims/all-claims/claims.service';
import {MyPetsService} from '../../../modules/my-pets/my-pets.service';
import {IMAGECONSTANTS} from '../../constants/image-constants';
import {ROUTINGCONSTANTS} from '../../constants/routing-constants';
import {GtmEventsService} from '../../services/gtm-events.service';
import {STATUSES} from '../../constants/claims';
import {CurrencyPipe, Location} from '@angular/common';
import {BillingPaymentService} from '../../services/billing-payment.service';
import {PolicyDetails} from '../../../models/policy-details';
import {HYPERLINKSCONSTANTS} from '../../constants/hyperlinks-constants';
import {EncrDecrService} from '../../../core/services/encr-decr.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { JwtService } from '../../../core/services/jwt.service';
import { FullstoryService } from '../../services/fullstory.service';

@Component({
  selector: 'app-claim-details',
  templateUrl: './claim-details.component.html',
  styleUrls: ['./claim-details.component.scss']
})
export class ClaimDetailsComponent implements OnInit {
  public claim: Claim;
  public claimDetails: ClaimDetail[] = [];
  public claimNumber: any;
  public content = CONTENT;
  public deductibleApplied: any = [];
  public displayProgressSpinner = 0;
  public imageConstants = IMAGECONSTANTS;
  public hyperlinkConstants = HYPERLINKSCONSTANTS;
  public name: string;
  public paidAmount: any = [];
  public plan = '';
  public policy: PolicyDetails;
  public policyNumber: any;
  public renewal: any;
  public routingConstants = ROUTINGCONSTANTS;
  public submittedAmount: any = [];
  public userId: string;
  public value = 50;
  public iconObj: any;
  public petSpeciesUrl: string;

  @ViewChild('diagnosisModal') diagnosisModalTemplate: TemplateRef<any>;

  constructor(private billingPaymentService: BillingPaymentService,
              private location: Location,
              private claimService: ClaimsService,
              private currencyPipe: CurrencyPipe,
              private encDcrService: EncrDecrService,
              private gtmService: GtmEventsService,
              private myPetService: MyPetsService,
              private route: ActivatedRoute,
              private jwtService: JwtService,
              private fullstoryService: FullstoryService,
              public dialog: MatDialog) {
    this.getClaimNoFromRoute();
  }

  ngOnInit() {
    this.getPolicy();
    this.getClaim();
    this.setGtmEvents();
    this.getSpeciesIcons();
    this.checkForPetType();
  }

  isInProcess(): boolean {
    return this.claim && this.claim.status && this.claim.status.toLowerCase() === STATUSES.INPROCESS;
  }

  // setCanineImage(policyNumber) 
  // {
  //   let showDogPicture = true;
  //   if (policyNumber) {
  //     const policyInfoList = JSON.parse(sessionStorage.getItem("policyList"));
  //     if(policyInfoList){
  //       const selectedPolicy = policyInfoList?.find(p=>p.policyNumber === policyNumber);
  //       showDogPicture = (selectedPolicy && selectedPolicy.pet && selectedPolicy.pet.species && selectedPolicy.pet.species.toUpperCase() === "CANINE");
  //     }
  //   }
  //   return showDogPicture; 
  // }

  checkForPetType() 
  {
    if (this.policyNumber) {
      const policyInfoList = JSON.parse(sessionStorage.getItem("policyList"));
      if(policyInfoList){
        const selectedPolicy = policyInfoList?.find(p=>p.policyNumber === this.policyNumber);
        switch(selectedPolicy?.pet?.species?.toUpperCase())
        {
          case "CANINE":
            this.petSpeciesUrl = this.iconObj?.canineIcon;
            break; 
          case "FELINE":
            this.petSpeciesUrl = this.iconObj?.felineIcon;
            break;
          case "REPTILE":
            this.petSpeciesUrl = this.iconObj?.reptileIcon;
            break;  
          case "AVIAN":
            this.petSpeciesUrl = this.iconObj?.avianIcon;
            break; 
          case "EXOTIC":
            this.petSpeciesUrl = this.iconObj?.exoticIcon;
            break; 
          default:
            this.petSpeciesUrl = this.iconObj?.canineIcon;
            break;
        }
      }
    }
    
  }

  getSpeciesIcons() {
    const portalSpeciesIcons = JSON.parse(sessionStorage.getItem('portalSpeciesIcons'));
    if(portalSpeciesIcons) {
      this.filterIcons(portalSpeciesIcons);
    }
  }

  filterIcons(iconList) {
    if(iconList) {
      const canineIcon = iconList?.canineIcon?.fields?.file?.url;
        const felineIcon = iconList?.felineIcon?.fields?.file?.url;
        const exoticIcon = iconList?.exoticIcon?.fields?.file?.url;
        const reptileIcon = iconList?.reptileIcon?.fields?.file?.url;
        const avianIcon = iconList?.avianIcon?.fields?.file?.url;  
      
      this.iconObj = {
        canineIcon : canineIcon,
        felineIcon: felineIcon,
        reptileIcon: reptileIcon,
        avianIcon: avianIcon,
        exoticIcon: exoticIcon       
      }
    }
    
  }
  private getClaimNoFromRoute() {
    this.route.params.subscribe(params => {
      this.claimNumber = params['claimNumber'];
      this.policyNumber = this.encDcrService.get(params['policyNumber']);
      this.renewal = params['renewal'];
      const policyInfoList = JSON.parse(sessionStorage.getItem("policyList"));
      if(policyInfoList){
        const selectedPolicy = policyInfoList?.find(p=>p.policyNumber === this.policyNumber);   
        if(selectedPolicy){
          sessionStorage.setItem("activeMemberId",selectedPolicy.memberId);
        }
      }
    });
  }

  isRENPolicy(){
      const currentUser = sessionStorage.getItem('currentUser');
      if(currentUser){
      const decodeJwt = this.jwtService.DecodeToken(currentUser);
      const policyList = decodeJwt['policyList'];
      const renPolicyDataList = policyList.find(p=>p.policyNumber === this.policyNumber);
      if(renPolicyDataList && renPolicyDataList.sourceSystem==='REN'){
        return true;
      }else{
        return false;
      }
      }
  }

  navigateToFAQs(label, faq) {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Link to FAQs',
      eventLabel: `FAQ Link Label: ${label}`,
      eventValue: `FAQ Link Value:  ${faq}`,
      userId: sessionStorage.getItem('userId')
    };
    this.fullstoryService.recordCustomEvent(event.event, { message: event });
  }

  setGtmEvents() {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'See Details Button',
      eventLabel: 'See Details Button For Claim',
      eventValue: `Claim Details Button For ${this.policyNumber} - ${this.claimNumber}`,
      userId: sessionStorage.getItem('userId')
    };
    this.fullstoryService.recordCustomEvent(event.event, { message: event });
  }

  public claimDetailsRecieved(response: any) {
    if (response && response.ClaimDetailsResponse){
    const claimDtl: any=response.ClaimDetailsResponse;
      this.claimDetails = claimDtl.ClaimDetails ? claimDtl.ClaimDetails.filter(claimDetail => claimDetail.categoryType !== 600):[];        
        this.deductibleApplied =  claimDtl.deductibleApplied;
        this.paidAmount =  claimDtl.paidAmount;
      _.forEach(this.claimDetails, diagnosis => {
        this.submittedAmount.push(Number(diagnosis.submittedExpense));
        //this.deductibleApplied = diagnosis.deductibleApplied;
        //this.paidAmount = diagnosis.paidAmount;
      });
    }
  }

  public claimSummaryRecieved(response: any) {
    const responseNew: any = response.ClaimSummaryResponse;
    //this.claim = response.claims.reduce((acc, curr) => {
    this.claim = responseNew.ClaimSummary.reduce((acc, curr) => curr.claimNumber === this.claimNumber ? curr : acc);
    if(this.plan === '') {
      this.plan = this.claim.policyPlanType;
    }
    
    this.getClaimDetailsByClaimNo();
  }

  public getClaim() {
    this.displayProgressSpinner++;
    this.claimService.getClaimSummary(this.policyNumber, this.renewal).subscribe({
      next: response => this.claimSummaryRecieved(response),
      complete: () => this.displayProgressSpinner--
    });
  }

  public getClaimDetailsByClaimNo() {
    this.displayProgressSpinner++;
    this.claimService.getClaimDetailsByClaimNo(this.claimNumber).subscribe({
      next: response => this.claimDetailsRecieved(response),
      complete: () => this.displayProgressSpinner--
    });
  }

  public getDeductibleApplied() {
    return this.deductibleApplied;
  }

  public getPaidAmount() {
    return this.paidAmount;
  }

  public getPolicy() {
    this.billingPaymentService.getPolicyDetails(this.policyNumber, this.renewal).subscribe({
      next: response => {
        this.policy = response.policyData;
        this.plan = (response.policyData && response.policyData.policyPlans && response.policyData.policyPlans[0] && response.policyData.policyPlans[0].planName) || '';
        this.name = response.petData.name || '';
        if(this.plan === '' && this.claim && this.claim.policyPlanType) {
          this.plan = this.claim.policyPlanType;
        }
      }
    });
  }

  public async getSsoURL() {
    this.displayProgressSpinner++;
    await this.myPetService.getPolicyDocumentsSso().subscribe({
      next: response => {
        if (response.ssoUrl) {
          if (window.innerWidth <= 768) {
            window.open(response.ssoUrl, '_parent');
          } else {
            window.open(response.ssoUrl, '_blank');
          }
        }
      },
      complete: () => {
        this.displayProgressSpinner--;
      }
    });
  }

  public async getEobDocURL() {
    this.displayProgressSpinner++;
    await this.myPetService.getPolicyDocumentsURL(this.policyNumber, this.claimNumber).subscribe({
      next: response => {
        // const docblob = window.URL.createObjectURL(response);
        // window.open(docblob, '_blank');
        if(response.docUrl){
          if (window.innerWidth <= 768) {
            window.open(response.docUrl, '_parent');
          } else {
            window.open(response.docUrl, '_blank');
          }
        }
      },
      error: (err) => { console.log(err); this.displayProgressSpinner--; },
      complete: () => {
        this.displayProgressSpinner--;
      }
    });
  }

  public goBack() {
    this.location.back();
  }

  public checkForAmount(status, amount) {
    amount = parseFloat(amount);
    if (status === 'Complete' && (!isNaN(amount) || amount === 0 || amount === '0' || amount === '')) {
      return this.currencyPipe.transform(amount);
    //} else if (status === 'In Process' && (amount === 0 || amount === "0" || amount === "")) {
    } else if (status === 'In Process') {
      return this.content.claimsPage.pending;
    } else {
      if(!isNaN(amount)){
        return this.currencyPipe.transform(amount);
      }
    }
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(this.diagnosisModalTemplate);

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  checkClaimStatus(status: string) {
    if (status === 'In Process') {
      return 'In Progress';
    }
    return status;
  }
}
